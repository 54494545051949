<template>
	<div>
		<ul>
			<li>
				<router-link to="/maps/china">China</router-link>
			</li>
		</ul>
	</div>
</template>

<script>

export default {
  name: 'Maps List',
}
</script>